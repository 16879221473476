import * as Modules from '../modules';
import { Routes } from "./routes.enum";
import { IRoute } from "./routes.route";

export const routes: IRoute[] = [
    // dashboard
    {
        path: Routes.Dashboard,
        component: Modules.DashboardPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    // order dashboard
    {
        path: Routes.OrderDashboard,
        component: Modules.OrderDashboardPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    // partner dashboard
    {
        path: Routes.PartnerDashboard,
        component: Modules.PartnerDashboardPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    // errors
    {
        path: Routes.TawreedError,
        component: Modules.TawreedErrorPage,
        title: "lbl_errors_title",
        needsAuth: false,
        mode: 'landing',
    },
    // auth
    {
        path: Routes.Login,
        component: Modules.LoginPage,
        title: "lbl_login",
        needsAuth: false,
        mode: 'landing',
    },
    // notifications
    {
        path: Routes.Notifications,
        component: Modules.NotificationsPage,
        title: "lbl_notifications",
        needsAuth: true,
        mode: 'normal',
    },
    // users
    {
        path: Routes.UsersSearch,
        component: Modules.UsersPage,
        title: "lbl_page_users",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.UsersNew,
        component: Modules.UserFormPage,
        title: "lbl_page_user_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.UsersDetails,
        component: Modules.UserFormPage,
        title: "lbl_page_user_details",
        needsAuth: true,
        mode: 'normal',
    },
    // customers
    {
        path: Routes.PartnersCustomersSearch,
        component: Modules.CustomersPage,
        title: "lbl_page_customers",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersCustomersNew,
        component: Modules.CustomerFormPage,
        title: "lbl_page_customer_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersCustomersDetails,
        component: Modules.CustomerFormPage,
        title: "lbl_page_customer_details",
        needsAuth: true,
        mode: 'normal',
    }, {
        path: Routes.PartnersCustomersView,
        component: Modules.CustomerViewPage,
        title: "lbl_page_customer_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersConnectionView,
        component: Modules.ConnectionViewPage,
        title: "lbl_page_customer_details",
        needsAuth: true,
        mode: 'normal',
    },
    // stores
    {
        path: Routes.PartnersStoresSearch,
        component: Modules.StoresPage,
        title: "lbl_page_stores",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersStoresNew,
        component: Modules.StoreFormPage,
        title: "lbl_page_store_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnersStoresDetails,
        component: Modules.StoreFormPage,
        title: "lbl_page_store_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PartnerConnectionsSearch,
        component: Modules.ConnectionsPage,
        title: "lbl_page_store_details",
        needsAuth: true,
        mode: 'normal',
    },
    //payment partners
    {
        path: Routes.PaymentPartnersSearch,
        component: Modules.PaymentPartnerPage,
        title: "lbl_page_payment_partner",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentPartnersNew,
        component: Modules.PaymentPartneFormPage,
        title: "lbl_page_payment_partner_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentPartnersDetails,
        component: Modules.PaymentPartneFormPage,
        title: "lbl_page_payment_partner_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentTransactionsSearch,
        component: Modules.PaymentTransactionsPage,
        title: "lbl_page_payment_transactions",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentTransactionDetails,
        component: Modules.PaymentTransactionPage,
        title: "lbl_page_payment_transaction_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentOrdersSearch,
        component: Modules.PaymentOrderPage,
        title: "lbl_page_payment_transactions",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PaymentOrderDetails,
        component: Modules.PaymentOrderDetailsPage,
        title: "lbl_page_payment_transactions",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.DeliveredOrdersSearch,
        component: Modules.DeliveredOrderPage,
        title: "lbl_page_wrong_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PlacedOrdersSearch,
        component: Modules.PlacedOrderPage,
        title: "lbl_page_wrong_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ShippedOrdersSearch,
        component: Modules.ShippedOrderPage,
        title: "lbl_page_wrong_orders",
        needsAuth: true,
        mode: 'normal',
    },
    //to update
    {
        path: Routes.WrongOrderDetails,
        component: Modules.WrongOrderDetailsPage,
        title: "lbl_page_wrong_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.BrokenOrdersSearch,
        component: Modules.BrokenOrderPage,
        title: "lbl_page_payment_transactions",
        needsAuth: true,
        mode: 'normal',
    },
    //delivery partners
    {
        path: Routes.DeliveryPartnersSearch,
        component: Modules.DeliveryPartnerPage,
        title: "lbl_page_delivery_partner",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.DeliveryPartnersNew,
        component: Modules.DeliveryPartneFormPage,
        title: "lbl_page_delivery_partner_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.DeliveryPartnersDetails,
        component: Modules.DeliveryPartneFormPage,
        title: "lbl_page_delivery_partner_details",
        needsAuth: true,
        mode: 'normal',
    },
    // products
    {
        path: Routes.ProductsSearch,
        component: Modules.ProductsPage,
        title: "lbl_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ProductsNew,
        component: Modules.ProductPage,
        title: "lbl_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ProductsDetails,
        component: Modules.ProductPage,
        title: "lbl_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    //unverified products
    // {
    //     path: Routes.UnverifiedProductsSearch,
    //     component: Modules.UnverifiedProductsPage,
    //     title: "lbl_products",
    //     needsAuth: true,
    //     mode: 'normal',
    // },
    {
        path: Routes.UnverifiedProductsDetails,
        component: Modules.UnverifiedProductpage,
        title: "lbl_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    // store-products
    {
        path: Routes.StoreProductsSearch,
        component: Modules.StoreProductsPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        
        path: Routes.SellerStoreProductSearch,
        component: Modules.SellerStoreProductsPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ImportStoreProductsSearch,
        component: Modules.ImportStoreProductPage,
        title: "lbl_store_products",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.StoreProductsNew,
        component: Modules.StoreProductPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.StoreProductsDetails,
        component: Modules.StoreProductPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },
    // // store prouducts upload
    // {
    //     path: Routes.StoreProductsStageSearch,
    //     component: Modules.StoreProductsStagePage,
    //     title: "lbl_products_upload",
    //     needsAuth: true,
    //     mode: 'normal',
    // },
    // sales-carts
    {
        path: Routes.SalesCartsSearch,
        component: Modules.CartsPage,
        title: "lbl_sales_carts",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartsNew,
        component: Modules.CartPage,
        title: "lbl_sales_carts_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartNew,
        component: Modules.NewCartpage,
        title: "lbl_sales_carts_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesCartsDetails,
        component: Modules.CartPage,
        title: "lbl_sales_carts_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | all
    {
        path: Routes.SalesOrdersSearch,
        component: Modules.OrdersPage,
        title: "lbl_sales_orders",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-stores-orders | placed
    {
        path: Routes.SalesOrdersPlacedSearch,
        component: Modules.StoresPlacedOrdersPage,
        title: "lbl_sales_stores_placed_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersPlacedDetails,
        component: Modules.StorePlacedOrdersPage,
        title: "lbl_sales_stores_placed_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-stores-orders | processing
    {
        path: Routes.SalesOrdersProcessingSearch,
        component: Modules.StoresProcessingOrdersPage,
        title: "lbl_sales_stores_processing_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersProcessingDetails,
        component: Modules.StoreProcessingOrdersPage,
        title: "lbl_sales_stores_processing_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    //sales-store-orders | ready for delivery
    {
        path: Routes.SalesOrdersReadySearch,
        component: Modules.StoresReadyOrderPage,
        title: "lbl_sales_stores_ready_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersReadyDetails,
        component: Modules.StoreReadyOrderPage,
        title: "lbl_sales_stores_ready_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-customers-orders | shipped
    {
        path: Routes.SalesOrdersShippedSearch,
        component: Modules.CustomersShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersShippedDetails,
        component: Modules.CustomerShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | all | details
    {
        path: Routes.PublicSalesOrdersDetails,
        component: Modules.PublicOrderPage,
        title: "lbl_sales_orders_details",
        needsAuth: false,
        mode: 'landing',
    },
    // sales-orders | all | details
    {
        path: Routes.SalesOrdersDetails,
        component: Modules.OrderPage,
        title: "lbl_sales_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrderTrackingHostory,
        component: Modules.OrderTrackingHistoryPage,
        title: "lbl_sales_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | all
    {
        path: Routes.SalesOrdersReturnedSearch,
        component: Modules.ReturnedOrdersPage,
        title: "lbl_sales_returned_orders",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | shipped
    {
        path: Routes.SalesOrdersReturnedShippedSearch,
        component: Modules.CustomersReturnedShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOrdersReturnedShippedDetails,
        component: Modules.CustomerReturnedShippedOrdersPage,
        title: "lbl_sales_customers_shipped_orders_details",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | new
    {
        path: Routes.SalesOrdersReturnedNew,
        component: Modules.ReturnedOrderFormPage,
        title: "lbl_sales_returned_order_new",
        needsAuth: true,
        mode: 'normal',
    },
    // sales-orders | returned | all | details
    {
        path: Routes.SalesOrdersReturnedDetails,
        component: Modules.ReturnedOrderPage,
        title: "lbl_sales_returned_order_details",
        needsAuth: true,
        mode: 'normal',
    },
    // finance | documents | new
    {
        path: Routes.FinanceDocumentType1,
        component: Modules.FinanceDocumentType1Page,
        title: "lbl_finance_documents_1",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType2,
        component: Modules.FinanceDocumentType2Page,
        title: "lbl_finance_documents_2",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType3,
        component: Modules.FinanceDocumentType3Page,
        title: "lbl_finance_documents_3",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType4,
        component: Modules.FinanceDocumentType4Page,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },
    
    {
        path: Routes.FinanceDocumentJournalType,
        component: Modules.FinanceDocumentJornalPage,
        title: "lbl_finance_documents_6",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType1Details,
        component: Modules.FinanceDocumentType1Page,
        title: "lbl_finance_documents_1",
        needsAuth: true,
        mode: 'normal',
    },
    
    {
        path: Routes.FinanceDocumentType2Details,
        component: Modules.FinanceDocumentType2Page,
        title: "lbl_finance_documents_2",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType3Details,
        component: Modules.FinanceDocumentType3Page,
        title: "lbl_finance_documents_3",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentType4Details,
        component: Modules.FinanceDocumentType4Page,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentTypeGeneralDetails,
        component: Modules.FinanceDocumentTypeGeneralPage,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.FinanceDocumentJournalTypeDetails,
        component: Modules.FinanceDocumentJornalPage,
        title: "lbl_finance_documents_4",
        needsAuth: true,
        mode: 'normal',
    },

    // reports
    {
        path: Routes.ReportsFinancialDocuments,
        component: Modules.ReportFinancialDocumentsPage,
        title: "lbl_reports_financial_documents",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsFinancialDashboard,
        component: Modules.ReportFinancialDashboardPage,
        title: "lbl_reports_financial_documents",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsFinancialStatementsPerPartner,
        component: Modules.ReportFinancialStatementsPerPartnerPage,
        title: "lbl_reports_financial_statements_per_partner",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsFinancialStatementsPerAccount,
        component: Modules.ReportFinancialStatementsPerAccountPage,
        title: "lbl_reports_financial_statements_per_account",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsSalesOrders,
        component: Modules.ReportSalesOrdersPage,
        title: "lbl_reports_sales_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsSalesReturnedOrders,
        component: Modules.ReportSalesReturnedOrdersPage,
        title: "lbl_reports_sales_returned_orders",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsInvoices,
        component: Modules.ReportInvoicesPage,
        title: "lbl_reports_invoices",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsReturnedInvoices,
        component: Modules.ReportReturnedInvoicesPage,
        title: "lbl_reports_invoices_returned",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ReportsTrialBalance,
        component: Modules.ReportTrialBalancePage,
        title: "lbl_reports_trial_balance",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.TransactionsDetails,
        component: Modules.TransactionPage,
        title: "lbl_page_topup_transaction_details",
        needsAuth: true,
        mode: 'normal',
    },
    // zones | stores
    {
        path: Routes.StoreZonesSearch,
        component: Modules.StoreZonesPage,
        title: "lbl_store_zones",
        needsAuth: true,
        mode: 'normal',
    },
    // zones
    {
        path: Routes.ZonesSearch,
        component: Modules.ZonesPage,
        title: "lbl_zones",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ZonesNew,
        component: Modules.ZonePage,
        title: "lbl_zones_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ZonesDetails,
        component: Modules.ZonePage,
        title: "lbl_zones_details",
        needsAuth: true,
        mode: 'normal',
    },
    //
    {
        path: Routes.MarketingNotificationCampaign,
        component: Modules.NotificationCampaignPage,
        title: "lbl_marketing_campaign",
        needsAuth: true,
        mode: 'normal',
    },
    // topup | partners
    {
        path: Routes.TopUpPartnersSearch,
        component: Modules.TopUpPartnersPage,
        title: "lbl_page_topups",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.TopUpPartnersNew,
        component: Modules.TopUpPartnerPage,
        title: "lbl_page_topup_new",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.TopUpPartnersDetails,
        component: Modules.TopUpPartnerPage,
        title: "lbl_page_topup_details",
        needsAuth: true,
        mode: 'normal',
    },
    // topup | transactions
    {
        path: Routes.TopUpTransactionsSearch,
        component: Modules.TopUpTransactionsPage,
        title: "lbl_page_topup_transactions",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.TopUpTransactionsDetails,
        component: Modules.TopUpTransactionPage,
        title: "lbl_page_topup_transaction_details",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.WalletTransaction,
        component: Modules.WalletTransactionPage,
        title: "lbl_wallet_transaction_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.WalletTransactionDetails,
        component: Modules.WalletTransactionDetails,
        title: "lbl_wallet_transaction_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SettingsCategory,
        component: Modules.CategoryPageTable,
        title: "lbl_category_table_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SettingsCategoryNew,
        component: Modules.CategoryPageForm,
        title: "lbl_category_new_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SettingsCategoryDetials,
        component: Modules.CategoryPageForm,
        title: "lbl_category_details_page",
        needsAuth: true,
        mode: 'normal',
    },

    {
        path: Routes.SettingsBanner,
        component: Modules.BannerPageTable,
        title: "lbl_banner_table_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SettingsBannerNew,
        component: Modules.BannerPageForm,
        title: "lbl_banner_new_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SettingsBannerDetials,
        component: Modules.BannerPageForm,
        title: "lbl_banner_details_page",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.ImportHelper,
        component: Modules.ImportHelper,
        title: "import helper",
        needsAuth: true,
        mode: 'normal',
    }, {
        path: Routes.ImportHelperDetails,
        component: Modules.ImportDetailsHelper,
        title: "import helper",
        needsAuth: true,
        mode: 'normal',
    },

    // sales offer
    {
        path: Routes.SalesOfferSearch,
        component: Modules.SalesOffersPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOfferNew,
        component: Modules.SalesOfferPage,
        title: "lbl_dashboard",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.SalesOfferDetails,
        component: Modules.SalesOfferPage,
        title: "lbl_store_product_new",
        needsAuth: true,
        mode: 'normal',
    },

    //posts
    {
        path: Routes.PostsSearch,
        component: Modules.PostsPage,
        title: "lbl_page_posts",
        needsAuth: true,
        mode: 'normal',
    },

    {
        path: Routes.PostsNew,
        component: Modules.PostFormPage,
        title: "lbl_page_posts",
        needsAuth: true,
        mode: 'normal',
    },

    {
        path: Routes.PostsDetails,
        component: Modules.PostFormPage,
        title: "lbl_page_posts",
        needsAuth: true,
        mode: 'normal',
    },
    {
        path: Routes.PostView,
        component: Modules.PostViewPage,
        title: "lbl_page_posts",
        needsAuth: true,
        mode: 'normal',
    }

];
