import React, { useEffect, useMemo, useState } from "react";
import { DashboardItemValue, topOrdedPharmaciesDto } from "../../domain";
import { tawreedTranslationService } from "../../../../common/translations";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import { TawreedCalendar, TawreedFormField, TawreedMultiSelect } from "../../../../common/components/form";
import { SelectButton } from "primereact/selectbutton";
import { PrimeIcons } from "primereact/api";
import { DateUtils } from "../../../../helpers/date";
import { AuthUtils } from "../../../auth/domain";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { PartnerType } from "../../../partners/data";
import { CustomerMultiSelect, StoreMultiSelect } from "../../../partners/presentaion/components";
import { priceListDto } from "../../../catalog/store-products/domain";
import { PriceListServcie } from "../../../catalog/store-products/domain/services/price-list.service";
import { GlobalContext } from "../../../../context";
import { PartnerDto } from "../../../partners/domain";
import { PartnersChartDto } from "../../../dashboard/domain";


declare type DashboardTopOrderdPharmaciesProps = {

    /**
            *
            */
    className: string;

    /**
     *
     */
    loading: boolean;

    onSearch(startDate: Date, endDate: Date): void;


    /**
     * counts
     */
    counts: topOrdedPharmaciesDto | undefined;

    calculator: (value: DashboardItemValue) => string;
}

declare type DashboardTopOrderdPharmaciesState = {

    data: {
        /**
         *
         */
        labels: Array<string>;

        /**
         *
         */
        datasets: Array<{ backgroundColor: string; data: Array<any>; hoverBackgroundColor?: string, weight?: number }>;
    };
    //
    startDate: Date;
    endDate: Date;
}

export const DashboardTopOrderdPharmacies: React.FC<DashboardTopOrderdPharmaciesProps> = (props) => {

    const { constants: { constants } } = React.useContext(GlobalContext);

    const chartData = {
        labels: props.counts?.data.map(e => tawreedTranslationService.translate(e.label)),
        datasets: [
            {
                data: props.counts?.data.map(e => props.calculator(e.value)),
                backgroundColor: props.counts?.color,
                hoverBackgroundColor: props.counts?.color,
                weight: .5,
            },
        ]
    };

    const [state, setState] = React.useState<DashboardTopOrderdPharmaciesState>({
        data: {
            labels: [],
            datasets: [
                {
                    backgroundColor: '#FFA726',
                    data: []
                }
            ]
        },
        //
        startDate: DateUtils.now(-7),
        endDate: DateUtils.now(),
    });
    const onChange = (sender: 'startDate' | 'endDate', value: Date) => {
        setState({...state, [sender]: value});
    }

    React.useEffect(() => {

        let mounted = true;

        if (mounted && !props.loading) {
            props.onSearch(state.startDate, state.endDate);
        }

        return () => {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.startDate, state.endDate]);
    React.useEffect(
        () => {

            setState(previous => {
                return {
                    ...previous,
                    data: {
                        ...previous.data,
                        labels: props.counts?.data.map(e => tawreedTranslationService.translate(e.label)) || [],
                        datasets: [
                            {
                                label:'',
                                data: props.counts?.data.map(e => props.calculator(e.value)) || [],
                                backgroundColor: props.counts?.color || '',
                                hoverBackgroundColor: props.counts?.color,
                                weight: .5,
                            },
                        ] 
                    },
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.counts, props.calculator]);
    const lightOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            title: {
                display: true,
                //text: props.counts?.value ? tawreedTranslationService.translate(props.counts?.total.label, props.calculator(props.counts?.total.value)) : '',
            },
            legend: {
                position: 'left',
                align: 'center',
                labels: {
                    font: {
                        family: AuthUtils.current().language === 'ar' ? '--arabic-font-family' : 'var(--font-family)'
                    },
                    color: '#495057'
                }
            },
        }
    };


    const header = () => {
        return (
            <div className="grid">
                <div className="col-12">
                        <span>
                            {tawreedTranslationService.translate('lbl_partnerdashboard_top_ordered_pharmacies_chart_title')}
                        </span>
                    </div>
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-6">
                            </div>
                            <div className="col-6">
                                <div className="flex justify-content-start sm:justify-content-end">
                                    <i className={"my-auto mr-2 " + PrimeIcons.CALENDAR} style={{fontSize: '1.5rem'}}/>
                                    <div style={{width: '10rem'}}>
                                        <TawreedCalendar render="standalone" disabled={props.loading} value={state.startDate} onChange={e => onChange('startDate', e.value as Date)}/>
                                    </div>
                                    <div style={{width: '1rem', height: '1rem'}}/>
                                    <div style={{width: '10rem'}}>
                                        <TawreedCalendar render="standalone" disabled={props.loading} value={state.endDate} onChange={e => onChange('endDate', e.value as Date)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }

    return (
        <Card className={"card " + props.className} header={header()}>
            {
                (props.loading) ? <Skeleton /> : <Chart key="tawreed_dashboard_pharmacies_per_governate_chart" id="tawreed_dashboard_pharmacies_per_governate_chart" type="bar" height="16rem" data={state.data} options={lightOptions} style={{ width: '100%' }} />
            }
        </Card>
    )
}
