import { JObject } from "../../../../common/data/models";
import { topOrdedPharmaciesDto } from "../../domain";

export const TopOrderedPharamciesChartMapper = {

    fromJson(json? : Array<JObject>): topOrdedPharmaciesDto {
        if (!json || !json.length) {
            return {
            //    total: {
            //     label: 
            //    },
               data: [],
               color: '#FFA726',
            };
        }
        return {
            color: '#FFA726',
            data: json.map(e => {
                    return {
                        name: e.label,
                        label: e.label,
                        value: e.value2,
                    }
                }),
        };
    },
}