import { buildPaginatedRequest, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { PartnerDto } from "../../../partners/domain";
import { OrderDto } from "../../../sales/orders/domain";
import { PartnerDashboardRepository } from "../../data";
import { newJoinersChartDto, PartnerCountsDto, pharmaciesPerGovernateChartDto, topOrdedPharmaciesDto } from "../dtos";

export class DashboardService {

    private readonly repository = new PartnerDashboardRepository();


    public getNewJoinersChart(startDate: Date, endDate: Date, statusIds: number[] | undefined): Promise<newJoinersChartDto> {
        return this.repository.getNewJoinersChart(startDate, endDate, statusIds);
    }
    public getPharmaciesPerGovernateChartsCounts(): Promise<pharmaciesPerGovernateChartDto> {
        return this.repository.getPharmaciesPerGovernateChartsCounts();
    }
    public getTopOrderedPharmacies(startDate: Date, endDate: Date): Promise<topOrdedPharmaciesDto> {
        return this.repository.getTopOrderedPharmacies(startDate, endDate);
    }

    public getPartnerCount(startDate: Date, endDate: Date): Promise<PartnerCountsDto> {
        return this.repository.getPartnerCount(startDate, endDate);
    }
    public searchOrders(arg: PaginatedRequestParams | string): Promise<PaginatedResponse<OrderDto>> {
        const request = typeof arg === 'string' ?
            buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'partnerName', multiSortMeta: undefined },
                filters: {
                    orderId: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: arg,
                                matchMode: "contains",
                            },
                        ],
                    },
                }
            })
            :
            buildPaginatedRequest({ ...arg, filters: { ...arg.filters } });
        return this.repository.searchOrders(request);
    }
    public searchPartners(arg: PaginatedRequestParams | string): Promise<PaginatedResponse<PartnerDto>> {
        const request = typeof arg === 'string' ?
            buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'partnerName', multiSortMeta: undefined },
                filters: {
                    orderId: {
                        operator: 'AND',
                        constraints: [
                            {
                                value: arg,
                                matchMode: "contains",
                            },
                        ],
                    },
                }
            })
            :
            buildPaginatedRequest({ ...arg, filters: { ...arg.filters } });
        return this.repository.searchPartners(request);
    }
    //  public print(reportUrl: string): Promise<boolean> {
    //         return new Promise((resolve, reject) => {
    //             try {
    //                 WindowUtils.print(reportUrl);
    //                 resolve(true);
    //             } catch {
    //                 reject();
    //             }
    //         });
    //     };
}
